@use '../../../mb-styles/utils/variables';
@use '../bootstrap';

.navbar.navbar-dark {
    a {
        color: variables.$white;
        text-decoration: none;
    }

    a.nav-link:hover {
        text-decoration: underline;
    }
}

@include bootstrap.media-breakpoint-down(md) {
    .navbar-brand {
        flex: 1;
        display: flex;
        margin-right: 0;
    }

    .navbar-toggler {
        margin-left: auto;
        align-self: center;
    }
}