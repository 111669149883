@use '../utils/variables';
@use '../../external-styles/bootstrap/bootstrap';

header {
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.page-header-container {
    margin-top: -2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid variables.$gray;
    background-color: variables.$surface-base;

    .row {
        justify-content: space-between;
        align-items: center;
        height: 57px;

        h3 {
            margin-bottom: 0;
            @include bootstrap.media-breakpoint-down(md) {
                @include bootstrap.font-size(18px);
            }

            @include bootstrap.media-breakpoint-down(sm) {
                @include bootstrap.font-size(16px);
            }
        }

        .page-link-container {
            border-right: solid 1px variables.$gray;
            background-color: variables.$surface-darker;
            color: variables.$charcoal-600;
            height: 100%;
            padding: 0;

            > a.return-link {
                display: flex;
                height: 100%;
                padding-right: 1rem;
                padding-left: 1rem;
                align-items: center;
                text-decoration: none;

                > .material-icons {
                    font-weight: 600;
                }
            }
        }
    }
}