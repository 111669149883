@use '../utils/variables';
@use '../../external-styles/bootstrap';

// Reusable font styling with optional passable parameters
@mixin mb-font($size: variables.$fs-md, $weight: variables.$fw-400, $color: initial) {
    @include bootstrap.font-size($size);
    font-weight: $weight;
    color: $color;
}

/* ----- Generated Font Classes ----- */

@each $font-weight, $value in variables.$mb-font-weights {
    .#{$font-weight} {
        font-weight: $value !important;
    }
}

@each $font-size, $value in variables.$mb-font-sizes {
    .#{$font-size} {
        @include bootstrap.font-size($value);
    }
}