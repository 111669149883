@use '../utils/variables';
@use '../../external-styles/bootstrap';

a.external-link[target=_blank] {
    font-weight: variables.$fw-600;
    display: inline-block;
    align-items: center;
    text-decoration: none;

    &:after {
        font-family: 'Material Icons';
        content: "open_in_new";
        margin-left: 0.25rem;
        color: variables.$charcoal-400;
        font-weight: variables.$fw-500;
        @include bootstrap.font-size(variables.$fs-md-lg);
    }
}