.accordion .accordion .accordion-button {
    padding: 0.5rem;
}

.accordion-header {
    &.hide-button {
        .accordion-button::after {
            display: none;
        }
    }
}

.empty-accordion > button {
    &::after {
        display: none
    }
}