@use '../utils/variables';
@use '../../external-styles/bootstrap';

.presenter-contact-meta {
  @include bootstrap.media-breakpoint-up(sm) {
    display: flex;
    white-space: nowrap;
  }
}

.presenter-contact {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.presenter-sep:after,
.presenter-contact-meta:after {
  content: "|";
  display: inline-block;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  color: variables.$charcoal-500;

  @include bootstrap.media-breakpoint-down(md) {
    display: none;
  }
}

.presenter-sep:last-child:after {
  display: none;
}