@use '../../../mb-styles/utils/variables';
@use '../bootstrap';

.card-link {
    color: initial;
    text-decoration: none;

    &:hover {
        color: initial;
    }
}

.card-title {
    @include bootstrap.font-size(17px);

    a {
        text-decoration: none;
    }
}

.card-image-container {
    display: flex;
    align-self: stretch;
    @extend .border-end;
    width: 115px;
    min-height: 115px;
    height: 100%;
    
    .logo-container {
        flex: 1;
        padding: 0.75rem;
        width: 100%;
    }

    img {
        width: 100%;
        max-width: 115px;
    }
}

.card-image-container-mobile {
    width: 100px;

    img {
        width: 100%;
        max-width: 115px;
        height: auto;
    }
}

.card-text {
    .logo-container {
        max-width: 80px;
        padding: 0.5rem;

        img {
            width: 100%;
        }
    }
}

.card-header {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    @include bootstrap.font-size(variables.$fs-lg);

    @include bootstrap.media-breakpoint-down(sm) {
        @include bootstrap.font-size(17px);
    }

    > span a {
        text-decoration: none;
        font-weight: variables.$fw-600;
    }
}