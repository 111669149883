@use '../utils/variables';
@use '../../external-styles/bootstrap';

%carousel-control {
    position: absolute;
    align-self: center;
    padding-bottom: 32px;

    @include bootstrap.media-breakpoint-down(md) {
        bottom: 0;
        align-self: initial;
        z-index: 2;
        padding-bottom: 0;
    }
}

%carousel-control-icon {
    -webkit-filter: invert(1) grayscale(100);
    filter: invert(1) grayscale(100);

    @include bootstrap.media-breakpoint-down(md) {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.mb-carousel-control-prev {
    @extend %carousel-control;
    left: 12px;

    .carousel-control-prev-icon {
        @extend %carousel-control-icon;
    }

}

.mb-carousel-control-next {
    @extend %carousel-control;
    right: 12px;

    .carousel-control-next-icon {
        @extend %carousel-control-icon;
    }
}

.mb-carousel-inner {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 24px;
    overflow: hidden;
    min-height: 210px;

    &::after {
        display: block;
        clear: both;
        content: "";
    }

    @include bootstrap.media-breakpoint-down(md) {
        width: 100%;
        padding-bottom: 40px;
    }
}

.mb-carousel-indicators {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    z-index: 2;

    > button[data-bs-target] {
        margin-left: 6px;
        margin-right: 6px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        opacity: .5;
        transition: 0.5s ease;
        border: 1px solid transparent;
        background-color: variables.$gray;

        &.active {
            opacity: 1;
            background-color: variables.$blue;
        }
    }
}

.mb-carousel-item-next,
.mb-carousel-item-prev,
.carousel-item.active {
    display: block;
}

.active.mb-carousel-item-end,
.mb-carousel-item-next:not(.mb-carousel-item-start) {
    transform: translateX(100%);
}

.active.mb-carousel-item-start,
.mb-carousel-item-prev:not(.mb-carousel-item-end) {
    transform: translateX(-100%);
}

@include bootstrap.media-breakpoint-down(md) {
    .mb-carousel .card-description-container {
        height: 90px;
        overflow: auto;

        > p {
            margin-bottom: 0;
        }
    }
}