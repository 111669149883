@use '../utils/variables';

/* ----- Common ----- */

body {
	overflow: hidden;
}

.d-flex1 {
    flex: 1;
}

.cursor-default {
    cursor: default !important; 
}

.cursor-pointer {
    cursor: pointer;
}

.border-light {
    border-color: variables.$surface-darker !important;
}

.no-border {
    border: none !important;

    &:hover,
    &:active {
        border: none !important;
    }
}

.btn:disabled {
	border-color: variables.$charcoal-400 !important;
}