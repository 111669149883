@use '../utils/variables';
@use '../../external-styles/bootstrap';

.mb-button {
    @extend .btn-sm;
    color: variables.$charcoal-900;
    border-color: variables.$gray;
    background-color: variables.$surface-base !important;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);

    &:hover:not(.disabled):not(:disabled) {
        background-color: variables.$surface-base;
        background: none;
        color: variables.$charcoal-900;
        border-color: variables.$gray;
    }

    &:active {
        background-color: variables.$light-gray !important;
        color: variables.$charcoal-900 !important;
        border-color: variables.$gray !important;
    }

    &:disabled,
    &.disabled {

        &:hover {
            box-shadow: none;
        }
    }
}

.action-buttons-col {
    padding-top: 0.25rem;
    margin-top: 0;
    align-self: center;

    @include bootstrap.media-breakpoint-down(sm) {
        margin-top: 1rem;
        text-align: right;
    }

    .action-buttons-group {
        @include bootstrap.media-breakpoint-down(sm) {
            display: flex;
            justify-content: space-between;
        }

        text-align: right;
        
        
    }
}