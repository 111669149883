.nav-tabs {
    .nav-item {
        .nav-link {
            border: none;
            font-size: 1.5em;

            &.active {
                border-bottom: 4px solid black;
            }
        }
    }
}