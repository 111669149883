/* ----- Color System ----- */

// Brand Colors
$mb-gold-1: #FFEA00;
$mb-gold-2: #FFD200;
$mb-gold-3: #FBBF0E;
$mb-gold-4: #CCBB00;
$black: #000000;
$white: #FFFFFF;

// Charcoal
$charcoal-50: #F7FBFE;
$charcoal-100: #F2F6F9;
$charcoal-200: #ECF0F3;
$charcoal-300: #CACFD2;
$charcoal-400: #BCC0C2;
$charcoal-500: #9DA1A3;
$charcoal-600: #74777A;
$charcoal-700: #606366;
$charcoal-750: #414446;
$charcoal-800: #2F3335;
$charcoal-900: #202325;
$charcoal-925: #1C1E1F;
$charcoal-950: #191A1C;
$charcoal-975: #161719; // default ink
$charcoal-dark: #080808;

//Surface
$surface-light: $white;
$surface-dark: #F3F4F5;
$surface-darker: #E6E6E6;
$surface-base: #FAFAFA;
$light-gray: #DFDFDF;
$gray: #CCCCCC;

// Primary & Secondary Colors
$blue: #0d8aee;
$blue-light-1: #319df4;
$blue-light-2: #58b0f6;
$blue-light-3: #7fc2f8;
$blue-light-4: #badefb;
$blue-dark-1: #0c7fdb;
$blue-dark-2: #0b74c8;
$blue-dark-3: #095ea2;
$blue-dark-4: #07487c;

$green: #4CAF50;
$green-light-1: #67bd6b;
$green-light-2: #84ca87;
$green-light-3: #a1d6a3;
$green-light-4: #cce9ce;
$green-dark-1: #46a14a;
$green-dark-2: #409343;
$green-dark-3: #347736;
$green-dark-4: #285b2a;

$yellow: $mb-gold-2;
$yellow-light-1: #ffd929;
$yellow-light-2: #ffe052;
$yellow-light-3: #ffe87a;
$yellow-light-4: #fff2b8;
$yellow-light-5: #fffbe6;
$yellow-dark-1: #ebc100;
$yellow-dark-2: #d6b000;
$yellow-dark-3: #ad8f00;
$yellow-dark-4: #856d00;

$orange: #F57208;
$orange-light-1: #f8882e;
$orange-light-2: #f99f56;
$orange-light-3: #fbb67e;
$orange-light-4: #fdd7b9;
$orange-dark-1: #e16907;
$orange-dark-2: #ce6007;
$orange-dark-3: #a74e05;
$orange-dark-4: #7f3b04;

$red: #D32F2F;
$red-light-1: #da5050;
$red-light-2: #e17272;
$red-light-3: #e89393;
$red-light-4: #f3c5c5;
$red-dark-1: #c42929;
$red-dark-2: #b32626;
$red-dark-3: #911f1f;
$red-dark-4: #6f1717;

$purple: #8a55a1;
$purple-light-1: #9e6eb2;
$purple-light-2: #b08ac1;
$purple-light-3: #c3a5d0;
$purple-light-4: #dfcfe5;
$purple-dark-1: #7f4e94;
$purple-dark-2: #744787;
$purple-dark-3: #5e3a6d;
$purple-dark-4: #482c54;

$cyan: #00bcd4;
$cyan-light-1: #05e3ff;
$cyan-light-2: #34e8ff;
$cyan-light-3: #64edff;
$cyan-light-4: #acf6ff;
$cyan-dark-1: #00adc3;
$cyan-dark-2: #009eb2;
$cyan-dark-3: #008090;
$cyan-dark-4: #00626e;

$teal: #47C1BF;
$teal-light-1: #64cbc9;
$teal-light-2: #82d5d3;
$teal-light-3: #9fdfde;
$teal-light-4: #cbeeed;
$teal-dark-1: #3db6b4;
$teal-dark-2: #38a6a4;
$teal-dark-3: #2d8685;
$teal-dark-4: #236766;

$magenta: #C159A1;
$magenta-light-1: #cb74b0;
$magenta-light-2: #d58ebf;
$magenta-light-3: #dfa9ce;
$magenta-light-4: #eed1e5;
$magenta-dark-1: #bb4998;
$magenta-dark-2: #ac408b;
$magenta-dark-3: #8c3471;
$magenta-dark-4: #6b2856;

$indigo: #5C6AC4;
$indigo-light-1: #7682cd;
$indigo-light-2: #909ad7;
$indigo-light-3: #aab2e0;
$indigo-light-4: #d1d5ee;
$indigo-dark-1: #4b5bbe;
$indigo-dark-2: #4050b2;
$indigo-dark-3: #344090;
$indigo-dark-4: #28316e;

$mb-blues: (
    "base": $blue,
    "variations": (
        "light-1": $blue-light-1,
        "light-2": $blue-light-2,
        "light-3": $blue-light-3,
        "light-4": $blue-light-4,
        "dark-1": $blue-dark-1,
        "dark-2": $blue-dark-2,
        "dark-3": $blue-dark-3,
        "dark-4": $blue-dark-4,
    )
);

$mb-greens: (
    "base": $green,
    "variations": (
        "light-1": $green-light-1,
        "light-2": $green-light-2,
        "light-3": $green-light-3,
        "light-4": $green-light-4,
        "dark-1": $green-dark-1,
        "dark-2": $green-dark-2,
        "dark-3": $green-dark-3,
        "dark-4": $green-dark-4,
    )
);

$mb-yellows: (
    "base": $yellow,
    "variations": (
        "light-1": $yellow-light-1,
        "light-2": $yellow-light-2,
        "light-3": $yellow-light-3,
        "light-4": $yellow-light-4,
        "dark-1": $yellow-dark-1,
        "dark-2": $yellow-dark-2,
        "dark-3": $yellow-dark-3,
        "dark-4": $yellow-dark-4,
    )
);

$mb-oranges: (
    "base": $orange,
    "variations": (
        "light-1": $orange-light-1,
        "light-2": $orange-light-2,
        "light-3": $orange-light-3,
        "light-4": $orange-light-4,
        "dark-1": $orange-dark-1,
        "dark-2": $orange-dark-2,
        "dark-3": $orange-dark-3,
        "dark-4": $orange-dark-4,
    )
);

$mb-reds: (
    "base": $red,
    "variations": (
        "light-1": $red-light-1,
        "light-2": $red-light-2,
        "light-3": $red-light-3,
        "light-4": $red-light-4,
        "dark-1": $red-dark-1,
        "dark-2": $red-dark-2,
        "dark-3": $red-dark-3,
        "dark-4": $red-dark-4,
    )
);

$mb-purples: (
    "base": $purple,
    "variations": (
        "light-1": $purple-light-1,
        "light-2": $purple-light-2,
        "light-3": $purple-light-3,
        "light-4": $purple-light-4,
        "dark-1": $purple-dark-1,
        "dark-2": $purple-dark-2,
        "dark-3": $purple-dark-3,
        "dark-4": $purple-dark-4,
    )
);

$mb-cyans: (
    "base": $cyan,
    "variations": (
        "light-1": $cyan-light-1,
        "light-2": $cyan-light-2,
        "light-3": $cyan-light-3,
        "light-4": $cyan-light-4,
        "dark-1": $cyan-dark-1,
        "dark-2": $cyan-dark-2,
        "dark-3": $cyan-dark-3,
        "dark-4": $cyan-dark-4,
    )
);

$mb-teals: (
    "base": $teal,
    "variations": (
        "light-1": $teal-light-1,
        "light-2": $teal-light-2,
        "light-3": $teal-light-3,
        "light-4": $teal-light-4,
        "dark-1": $teal-dark-1,
        "dark-2": $teal-dark-2,
        "dark-3": $teal-dark-3,
        "dark-4": $teal-dark-4,
    )
);

$mb-magentas: (
    "base": $magenta,
    "variations": (
        "light-1": $magenta-light-1,
        "light-2": $magenta-light-2,
        "light-3": $magenta-light-3,
        "light-4": $magenta-light-4,
        "dark-1": $magenta-dark-1,
        "dark-2": $magenta-dark-2,
        "dark-3": $magenta-dark-3,
        "dark-4": $magenta-dark-4,
    )
);

$mb-indigos: (
    "base": $indigo,
    "variations": (
        "light-1": $indigo-light-1,
        "light-2": $indigo-light-2,
        "light-3": $indigo-light-3,
        "light-4": $indigo-light-4,
        "dark-1": $indigo-dark-1,
        "dark-2": $indigo-dark-2,
        "dark-3": $indigo-dark-3,
        "dark-4": $indigo-dark-4,
    )
);

$mb-colors: (
    "blue": $mb-blues,
    "green": $mb-greens,
    "yellow": $mb-yellows,
    "orange": $mb-oranges,
    "red": $mb-reds,
    "purple": $mb-purples,
    "cyan": $mb-cyans,
    "teal": $mb-teals,
    "magenta": $mb-magentas,
    "indigo": $mb-indigos,
);

$mb-charcoal-colors: (
    "charcoal-50": $charcoal-50,
    "charcoal-100": $charcoal-100,
    "charcoal-200": $charcoal-200,
    "charcoal-300": $charcoal-300,
    "charcoal-400": $charcoal-400,
    "charcoal-500": $charcoal-500,
    "charcoal-600": $charcoal-600,
    "charcoal-700": $charcoal-700,
    "charcoal-750": $charcoal-750,
    "charcoal-800": $charcoal-800,
    "charcoal-900": $charcoal-900,
    "charcoal-925": $charcoal-925,
    "charcoal-950": $charcoal-950,
    "charcoal-975": $charcoal-975
);

$mb-surface-colors: (
    "surface-light": $surface-light,
    "surface-dark": $surface-dark,
    "surface-darker": $surface-darker,
    "surface-base": $surface-base,
    "light-gray": $light-gray,
    "gray": $gray
);

/* ----- Fonts ----- */

$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 400; // base
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

$mb-font-weights : (
    "fw-100": $fw-100,
    "fw-200": $fw-200,
    "fw-300": $fw-300,
    "fw-400": $fw-400,
    "fw-500": $fw-500,
    "fw-600": $fw-600,
    "fw-700": $fw-700,
    "fw-800": $fw-800,
    "fw-900": $fw-900
);

$fs-xs: 11px !important;
$fs-sm: 13px !important;
$fs-md: 16px !important;
$fs-md-lg: 18px !important;
$fs-lg: 20px !important;
$fs-xl: 24px !important;
$fs-xxl: 40px !important;
$fs-mega: 80px !important;

$mb-font-sizes: (
    "fs-xs": $fs-xs,
    "fs-sm": $fs-sm,
    "fs-md": $fs-md,
    "fs-md-lg": $fs-md-lg,
    "fs-lg": $fs-lg,
    "fs-xl": $fs-xl,
    "fs-xxl": $fs-xxl,
    "fs-mega": $fs-mega
);