@use 'sass:color';
@use 'variables';

/* ----- Generated Color Classes ----- */

@each $mbColor, $value in variables.$mb-colors {
    @each $type, $typeVal in $value {
        @if #{$type} == "base" {
            .bg-#{$mbColor} {
                background-color: $typeVal;
            }

            .text-#{$mbColor} {
                color: $typeVal;
            }

            .text-important-#{$mbColor} {
                color: $typeVal !important;
            }

            .border-#{$mbColor} {
                border-color: $typeVal;
            }
        }
        @else {
            @each $variationKey, $variationValue in $typeVal {
                .bg-#{$mbColor}-#{$variationKey} {
                    background-color: $variationValue;
                }

                .text-important-#{$mbColor}-#{$variationKey} {
                    color: $variationValue !important;
                }
            }
        }
    }
}

@each $color, $value in variables.$mb-charcoal-colors {
    .text-#{$color} {
        color: $value !important;
    }

    .bg-#{$color} {
        background-color: $value !important;
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color, $value in variables.$mb-surface-colors {
    .bg-#{$color} {
        background-color: $value !important;
    }

    .border-#{$color} {
        border-color: $value !important;
    }
}