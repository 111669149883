@use '../utils/variables';

.profile-circle {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    flex: 0 0 70px;

    .logo-container & {
        margin: auto;
    }

    .profile-circle-initials {
        color: variables.$white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 30px;
        text-transform: uppercase;
    }  
}