@use '../utils/variables';
@use '../../external-styles/bootstrap';

.mb-label-group,
.mb-inline-label-group {
    >label, >.label-group-label {
        font-weight: variables.$fw-600;
        margin: 0;

        &:after {
            display: inline-block;
            content: ":";
            letter-spacing: 0.5rem;
        }
    }
}

.mb-label-group {
    >label, >.label-group-label {
        margin-bottom: 0.5rem;
        display: inline-block;
        white-space: nowrap;
    }
}

.mb-inline-label-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    >label, >.label-group-label {
        margin-bottom: 0;
        white-space: nowrap;
        align-self: start;

        +div,
        +span,
        +input,
        +select,
        +textarea {
            flex: 1;
            min-width: 0;

            @include bootstrap.media-breakpoint-down(sm) {
                margin-bottom: 0.5rem;
            }
        }

        @include bootstrap.media-breakpoint-down(sm) {
            margin-bottom: 0.5rem;
        }
    }

    .mb-plaintext { 
        line-height: 1em; 
        
        .btn-plain { 
            line-height: 1em; 
        } 
    }

    @include bootstrap.media-breakpoint-down(sm) {
        display: block;
    }
}

.mb-label-data-row {
    margin-bottom: 0.15rem;

    &:last-child {
        margin-bottom: 0;
    }

    .mb-label-group,
    .mb-inline-label-group {
        margin-right: 0.5rem;
        display: inline-flex;

        >label, >.label-group-label {
            margin-right: 0;
            margin-bottom: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        @include bootstrap.media-breakpoint-down(sm) {
            display: block;
            margin-bottom: 0.5rem;
        }
    }

    .mb-inline-label-group label ~ * {
        margin-bottom: 0;
    }
}

.mb-label-flex-columns {
    display: flex;
    align-items: flex-start;

    .mb-inline-label-group {
        @include bootstrap.media-breakpoint-down(sm) {
            display: flex;
            flex: 1;
        }
    }
}