@use '../utils/variables';
@use '../../external-styles/bootstrap';

.mb-flyout {
    width: 100% !important;
    max-width: 350px;
    padding-top : env(safe-area-inset-top, 10px);
    background-color: variables.$blue;

    @include bootstrap.media-breakpoint-down(md) {
        max-width: 100%;
    }
}
  
.mb-flyout-header {
    background-color: variables.$blue;
    color: variables.$white;
    padding: 10px;
	justify-content: space-between;

    .mb-flyout-header-title {
        @include bootstrap.font-size(24px);
        margin-bottom: 0;
    }

    @include bootstrap.media-breakpoint-down(lg) {
        .mb-flyout-header-title {
            @include bootstrap.font-size(20px);
        }
    }
	button.mb-button-close {
		padding: 0.25rem 0.25rem 0 0.25rem;

		&:hover {
			background-color: variables.$blue;
			border-color: variables.$blue;
		}
	}
}
  
.mb-flyout-body {
    height: calc(100vh - 56px);
    background-color: var(--bs-offcanvas-bg);
	padding: 0;
}

.flyout-navigation {
    .nav-link {
        padding: 1em;

        &:hover {
            background-color: variables.$charcoal-300;
        }
    }
}