@use '../utils/variables';
@use '../utils/fonts';
@use '../../external-styles/bootstrap';

// Below are styles related to the outer shell and main header elements
html,
body,
#root {
    min-height: 100vh;
}

#root {
    background-color: #fff;
	display: flex;
	flex-direction: column;
}

p {
    margin-bottom: 0.5rem;
}

#loginContainer {
    padding-top : env(safe-area-inset-top);
}

$headerHeight: 87px + env(safe-area-inset-top);
$footerHeight: 53px + env(safe-area-inset-bottom);

%container-defaults {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.container-body {
    @extend %container-defaults;
    padding-left: env(safe-area-inset-left, 10px);
    padding-right: env(safe-area-inset-right, 10px);
    min-height: calc(100vh - ($headerHeight + $footerHeight));
	margin-top: auto;
	margin-bottom: auto;
	flex: 1;
	display: flex;
}

.container-body-events {
    @extend %container-defaults;
    min-height: calc(100vh - 87px);
    background-color: variables.$charcoal-900;
    color: variables.$white;
    padding-bottom: env(safe-area-inset-bottom, 20px);

    .container-events {
        max-width: 900px;
        margin-top: 100px;
        flex: 1;
        display: flex;
        flex-direction: column;

        .border-light {
            --bs-border-opacity: 0.3;
        }
    }
}

.item-title {
    @include fonts.mb-font(variables.$fs-md, variables.$fw-500);
}

.notes-list .note-row:last-child {
    border: none !important;
}

.mb-group-item {
    &:hover {
        background-color: variables.$charcoal-200;
    }
}

.show-event-row {
    cursor: pointer;

    >[class*='col-'] {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &:hover {
        background: rgba(var(--bs-light-rgb), 0.1);

        .container-body & {
            background: rgba(var(--bs-light-rgb), 0.5);
        }
    }

    &.selected {
        font-weight: variables.$fw-500;
        background: rgba(var(--bs-light-rgb), 0.5);
    }
}