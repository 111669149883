@use '../utils/variables';
@use '../../external-styles/bootstrap';

.flag-container {
    margin-bottom: 1rem;

    @include bootstrap.media-breakpoint-up(sm) {
        position: absolute;
        right: 15px;
    }
}

.virtualFlag {
    background-color: variables.$purple;
}

.specialFlag {
    background-color: variables.$teal;
}

.card-body .item-flag-button {
    @include bootstrap.media-breakpoint-down(md) {
        padding-left: 0 !important;
    }
}

.customer-badge {
    display: inline-flex;
    align-items: center;
    background: variables.$light-gray;
    padding: 0.4rem 0.5rem 0.3rem 0.5rem;

    .btn-close {
        @include bootstrap.font-size(8px);
    }
}