.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: url('https://cdn.martinbros.com/content/mb-loader.gif') no-repeat center 100px rgba(255, 255, 255, 0.5) !important;
    background-size: 80px !important;

    &.loader-top {
        background-position: center 20px !important;
    }
}