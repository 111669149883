@use '../utils/variables';
@use '../../external-styles/bootstrap';

.header-search {
    display: flex;
    width: 100%;
    max-width: 700px;

    @include bootstrap.media-breakpoint-down(md) {
        display: none;
    }
}

.header-search-mobile {
    display: none;
    width: 100%;

    @include bootstrap.media-breakpoint-down(md) {
        display: flex;
    }
}

.header-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-footer {
    text-align: center;
    background: variables.$surface-darker;
    padding: 1rem;
    @include bootstrap.font-size(14px);

    a {
        color: initial;
    }
}

