@use '../utils/variables';

.mb-toast-container {
    position: fixed;
    top: 0.25rem;
    right: 0.25rem;

    .mb-toast-header {
        color: variables.$white;
    }

    .mb-toast-body {
        text-align: left;
    }
}