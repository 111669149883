@use '../../external-styles/bootstrap';

.mobile-nav-bar {
	height: 62px;
	display: none;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1;

	@include bootstrap.media-breakpoint-down(md) {
		display: flex !important;
	}
}

.app-footer {
	display: none;

	@include bootstrap.media-breakpoint-up(md) {
		display: block !important;
	}
}