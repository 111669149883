@use '../../mb-styles/utils/variables';

@forward '~bootstrap/scss/bootstrap' with (
    $blue:      variables.$blue,
    $gray-100:  variables.$charcoal-100,
    $gray-200:  variables.$charcoal-200,
    $gray-300:  variables.$charcoal-300,
    $gray-400:  variables.$charcoal-400,
    $gray-500:  variables.$charcoal-500,
    $gray-600:  variables.$charcoal-600,
    $gray-700:  variables.$charcoal-700,
    $gray-800:  variables.$charcoal-800,
    $gray-900:  variables.$charcoal-900,
    $body-color: variables.$charcoal-900,
    
    $font-size-base: 0.875rem,
    $headings-font-weight: 400,
    $text-muted: variables.$charcoal-500,

    $btn-padding-y: .5rem,
    $btn-padding-x: 1.1rem,
    $btn-padding-y-sm: .35rem,
    $btn-padding-x-sm: .7rem,
    $btn-padding-y-lg: .69rem,
    $btn-padding-x-lg: 3rem,

    $accordion-button-bg: variables.$white,
    $accordion-button-active-bg: variables.$surface-dark,

    $grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
    ),

    $container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1520px
    ),
);